import React from "react"
import { XMasonry, XBlock } from "react-xmasonry"
import "./index.scss"

export default function ProjectGrid({ projects }) {
  return (
    <div className="container" style={{ padding: "5px" }}>
      <XMasonry center={true}>
        {projects.map(project => (
          <XBlock>
            <div className="project-wrapper">
              <a href={project.projectLink} target="_blank" rel="noreferrer">
                <p className="project-heading">{project.heading}</p>
                <div
                  className="summary"
                  dangerouslySetInnerHTML={{ __html: project.description }}
                />
                <div className="description">
                  <p>{project.skillsInProject.replaceAll(",", " | ")}</p>
                </div>
              </a>
            </div>
          </XBlock>
        ))}
      </XMasonry>
    </div>
  )
}
