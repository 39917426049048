import { graphql } from "gatsby"
import React from "react"
import Nav from "../components/Nav"
import Layout from "../components/layout"
// import ProjectCard from "../components/ProjectCard.js/index.jsx"
import Seo from "../components/seo"
import ProjectGrid from "../components/ProjectGrid"

export default function projectsPage({ data }) {
  const projects = data.allDatoCmsProject.nodes

  return (
    <Layout>
      <Seo title="Projects" />
      <Nav />
      <div className="projects-page-wrapper">
        <ProjectGrid projects={projects} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query projectQuery {
    allDatoCmsProject(sort: { fields: meta___updatedAt, order: DESC }) {
      nodes {
        heading
        skillsInProject
        mainImage {
          gatsbyImageData(placeholder: BLURRED)
        }
        typeOfProject
        description
        projectLink
      }
    }
  }
`
